<template>
  <div class="content">
    <div v-if="loading" class="container-fluid">
      <div class="row h-100">
        <div class="col-12 h-100">
          <div class="page-wrapper justify-content-center align-items-center h-100">
            <loading />
          </div>
        </div>
      </div>
    </div>
    <perfect-scrollbar @ps-scroll-y="onScroll" v-else class="w-100" ref="ps">
      <div class="container-fluid">
        <div class="row justify-content-center">
          <div class="col-12" style="height:100px">
            <page-title />
          </div>
          <div class="col-12">
            <div class="page-wrapper">
              <page-wrapper-header
              :sorting="sorting"
                :titleItems="title"
                :listType="listingType"
                :fav="isFav"
                :tableView="getTableView"
                @fav="changeFav"
                @favType="changeFavType"
                @sorting="changeSort"
                @changeListingType="changeListingType"
                @changeTableView="onTable"
              />
              <div class="page-wrapper-body">
                <list
                  @click="clikModel"
                  @setItms="setItems"
                  @sortT="onSortTable"
                  :sortT="sortT"
                  :type="'model'"
                  :tableView="getTableView"
                  :listingType="listingType"
                  :getList="getTableView ? tableList : list"
                />
                <div v-if="notMoreData && !loadingMore && !getTableView" class="row">
                  <div class="col-12">
                    <h3 class="justify-content-center d-flex w-100 mb-5 text-Keine">Keine weiteren Daten</h3>
                  </div>
                </div>
                <div v-if="loadingMore" class="row">
                  <div class="col-12">
                    <loading :size="'sm'" />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </perfect-scrollbar>
    <sidebar-filter :path="path"/>
  </div>
</template>

<script>
import { mapMutations, mapGetters } from 'vuex'
import List from '@/components/common/List.vue'
import Loading from '../components/common/Loading.vue'
import PageTitle from '@/components/common/PageTitle.vue'
import SidebarFilter from '../components/common/SidebarFilter.vue'
import PageWrapperHeader from '../components/common/PageWrapperHeader.vue'
export default {
  components: { PageTitle, List, PageWrapperHeader, Loading, SidebarFilter },
  name: 'ModelList',
  data () {
    return {
      page: 1,
      list: [],
      path: '',
      query: {},
      title: [],
      sort: 'asc',
      paginator: {},
      loading: true,
      sorting: 'AZ',
      supplierId: null,
      notMoreData: false,
      loadingMore: false,
      listingType: 'box',
      isFav: false,
      reqFav: false,
      currentCol: 'name',
      currMore: { date: false, bl: false },
      tableList: [],
      sortT: {
        name: null,
        modelId: null,
        HerstellerModellName: null,
        status: null,
        PreisbindungVon: null,
        PreisbindungBis: null,
        pricelistUpdatedAt: null,
        tz: null,
        isExclusive: null,
        Handelsmarken: null,
        isFavorite: null
      }
    }
  },
  computed: {
    ...mapGetters(['getUserOneRole', 'getListingType', 'getFavorite', 'getTableView']),
    getList () {
      return this.list
    },
    modelCurrPos () {
      return this.$store.state.currPgState.model
    }
  },
  methods: {
    ...mapMutations(['SET_BREADCRUMB', 'SET_LISTING_TYPE', 'SET_FAVORITE', 'SET_TABLE_VIEW', 'SET_FAVORITE_TYPE', 'SET_CURRENT_PAGE_STATE', 'SET_MODEL_CURRENT_STATE']),
    onScroll ({ target: { scrollTop, clientHeight, scrollHeight } }) {
      if (scrollTop + clientHeight + 2 >= scrollHeight) {
        this.moreData()
      }
    },
    moreData () {
      if (this.page === this.paginator.totalPages) {
        this.notMoreData = true
        return
      }
      if (!this.loadingMore) {
        this.page++
        this.getModelList(true)
      }
    },
    clikModel (par) {
      this.SET_CURRENT_PAGE_STATE({ type: 'model', pos: this.$refs.ps.$el.scrollTop })
      this.SET_MODEL_CURRENT_STATE([this.list, this.title, this.sorting])
      if (this.$route.name === 'sellerModels') {
        this.$router.push(
          `/seller/model/${this.pad(par.modelId, 4)}?supplierId=${this.supplierId}`
        )
      }
      if (this.$route.name === 'buyerModels') {
        this.$router.push(
          `/buyer/model/${this.pad(par.modelId, 4)}?supplierId=${this.supplierId}`
        )
      }
    },
    async changeFav (show) {
      this.isFav = show
      this.SET_FAVORITE({ route: this.path, type: 'model', val: show })
      this.page = 1
      this.loading = true
      this.reqFav = true
      this.getModelList()
    },
    changeFavType (type) {
      this.SET_FAVORITE_TYPE(type)
      this.page = 1
      this.loading = true
      this.reqFav = true
      this.getModelList()
    },
    async getModelList (more = false) {
      try {
        if (more) {
          this.loadingMore = true
        }

        let filter = { modelStatus: 'active' }
        if (this.$route.query.modelStatus) {
          filter.modelStatus = this.$route.query.modelStatus
        }
        if (this.$route.query.modelId) {
          filter = { ...filter, modelId: this.$route.query.modelId }
        }
        if (this.$route.query.goodsGroup) {
          filter = { ...filter, goodsGroup: this.$route.query.goodsGroup }
        }
        if (this.$route.query.isExclusive) {
          filter = { ...filter, isExclusive: this.$route.query.isExclusive === 'true' }
        }
        if (this.$route.query.modelDiscontinued) {
          filter = { ...filter, modelDiscontinued: this.$route.query.modelDiscontinued === 'true' }
        }
        filter = { ...filter, access: this.$store.getters.getFavoriteType }
        let query = {
          page: this.page,
          id: this.supplierId,
          sortType: this.sort
        }
        if (Object.keys(filter).length > 0) {
          query = { ...query, filter: JSON.stringify(filter) }
        }
        const response = this.isFav ? await this.$api.getFavorites('models', query) : await this.$api.getModelList(query)
        this.paginator = response.data.data.paginator
        let items = response.data.data.items
        if (this.isFav) { items = items.filter(itm => itm.supplierId === this.supplierId) }
        this.title = [
          {
            title: 'Modelle',
            count: this.isFav ? items.length : response.data.data.statistics.totalModelCount
          },
          {
            title: this.isFav ? '' : 'Kataloge',
            count: this.isFav ? '' : response.data.data.statistics.totalCatalogueCount
          }
        ]
        if (more && this.tableList.length) this.tableList = this.tableList.concat(items)
        if (!more) {
          this.list = []
          this.tableList = items
        }
        this.onSortTable(this.currentCol, this.currMore, more || this.reqFav)
        this.reqFav = false
        items.map((item) => {
          const group = item.name ? item.name.toUpperCase().charAt(0) : '-'
          const findIndex = this.list.findIndex((item) => item.group === group)

          if (findIndex === -1) {
            this.list.push({
              group,
              items: []
            })
          }
          const findIndexIshave = this.list.findIndex(
            (item) => item.group === group
          )
          if (findIndexIshave !== -1) {
            this.list[findIndexIshave].items.push({ ...item })
          }
        })
      } catch (e) {
        console.error(e)
      } finally {
        this.loading = false
        if (more) {
          this.loadingMore = false
        }
      }
    },
    changeListingType (par) {
      if (this.getTableView) {
        this.SET_TABLE_VIEW(0)
      }
      this.SET_LISTING_TYPE(par === 'box' ? 0 : 1)
      this.listingType = par
    },
    changeSort (par) {
      if (par === 'AZ') {
        this.sort = 'asc'
      }
      if (par === 'ZA') {
        this.sort = 'desc'
      }
      this.sorting = par
      this.page = 1
      this.loading = true
      this.getModelList()
    },
    onSortTable (col, more, load = false) {
      if (!load) {
        this.currentCol = col
        this.currMore = more
        if (this.sortT[col]) {
          this.tableList = this.tableList.reverse()
          this.setSortDefault(col, this.sortT[col] === 'up' ? 'down' : 'up')
          return
        }
      }
      if (more.bl) {
        this.tableList = this.tableList.sort((a, b) => {
          return (a[col] === b[col]) ? 0 : a[col] ? -1 : 1
        })
      }
      if (more.date) {
        this.tableList = this.tableList.sort((a, b) => {
          if (a[col] && b[col]) {
            if (col === 'pricelistUpdatedAt') {
              return new Date(a[col]) - new Date(b[col])
            }
            return new Date(a[col].split('.').reverse().toString().replace(/,/g, '-')) -
              new Date(b[col].split('.').reverse().toString().replace(/,/g, '-'))
          }
          return false
        })
      }
      if (!more.cond) {
        this.tableList = this.tableList.sort((a, b) => {
          if (a[col] && b[col]) {
            return a[col].localeCompare(b[col])
          }
          return true
        })
      }
      if (load && this.sortT[col] === 'up') {
        this.tableList = this.tableList.reverse()
        this.setSortDefault(col, this.sortT[col])
      }
      this.setSortDefault(col, 'down')
    },
    setSortDefault (col, val) {
      this.sortT = {
        name: null,
        modelId: null,
        HerstellerModellName: null,
        status: null,
        PreisbindungVon: null,
        PreisbindungBis: null,
        pricelistUpdatedAt: null,
        tz: null,
        isExclusive: null,
        Handelsmarken: null,
        isFavorite: null
      }
      if (col) this.sortT[col] = val
    },
    onTable () {
      this.SET_TABLE_VIEW(1)
    },
    setItems (i, ix) {
      this.list[i].items[ix].isFavorite = !this.list[i].items[ix].isFavorite
    },
    pad (num, size) {
      num = num.toString()
      while (num.length < size) num = '0' + num
      return num
    }
  },
  created () {
    const routeName = this.$route.name
    const name = this.$route.query.seller
    this.supplierId = this.$route.query.id
    this.path = routeName === 'sellerModels' ? 'seller' : 'buyer'
    this.listingType = this.getListingType ? 'list' : 'box'
    if (!name || !this.supplierId) {
      if (routeName === 'sellerModels') {
        return this.$router.push('/seller')
      }
      if (routeName === 'buyerModels') {
        return this.$router.push('/buyer')
      }
    }
    const isSeller = this.getUserOneRole('isSellerUser')
    const isBuyer = this.getUserOneRole('isPurchaserUser')
    if (routeName === 'sellerModels' && isSeller === -1) {
      return this.$router.push('/access-denied')
    }
    if (routeName === 'buyerModels' && isBuyer === -1) {
      return this.$router.push('/access-denied')
    }
    this.isFav = this.getFavorite[this.path]?.model
    const data = [
      {
        title: name
      },
      {
        title: routeName === 'sellerModels' ? ' / VERKAUF' : ' / Einkauf'
      }
    ]
    this.SET_BREADCRUMB(data)
    if (this.modelCurrPos) {
      this.loading = false
      const state = Object.values(this.$store.state.modelCurrState)
      this.list = state[0]
      this.title = state[1]
      this.sorting = state[2]
      return
    }
    this.getModelList()
  },
  mounted () {
    if (this.modelCurrPos) this.$refs.ps.$el.scrollTop = this.modelCurrPos
  }
}
</script>
